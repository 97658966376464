import React, { useState, useEffect } from "react";
import pagestyles from "../evolve-framework/pages/pages.module.css";
import axios from "axios";
import apiurl from "../apiUrl";

function VideoForm({ pageId, setShowVideoform }) {
    const [videoData, setVideoData] = useState({ videopath: '', pageid: pageId });
    const [pageHasVideo, setPageHasVideo] = useState(false);

    const fetchurl = apiurl + "getvideobypageid.php";
    const savefetchurl = apiurl + "savepagevideo.php";
    const updatefetchurl = apiurl + "uppagevideo.php";

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, { pageId }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    const elementslength = response.data.length;
                    if (elementslength > 0) {


                        setPageHasVideo(true);
                        setVideoData(response.data);

                        console.log(videoData.pageid);
                    } else {
                        setPageHasVideo(false);
                    }
                }
            } catch (error) {
                console.error("Fehler", error);
            }
        };
        getData();
    }, [pageId]);

    const handleVideo = async () => {
        try {
            const response = await axios.post(savefetchurl, videoData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log(response.data);
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
        setShowVideoform(false);
    };

    const handleUpVideo = async () => {
        try {
            const response = await axios.post(updatefetchurl, videoData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {

            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
        setShowVideoform(false);
    };

    return (
        <section className={pagestyles.videoform}>
            <h5>Videopfad hinzufügen</h5>
            {pageHasVideo ? (
                <section className="mio-form-field tab-flex-4 flex-12">
                    <label>Video ( Zum Speichern Enter Taste drücken )</label>
                    <input
                        type="text"
                        value={videoData.videopath}
                        onChange={(e) => setVideoData({ ...videoData, videopath: e.target.value })}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleUpVideo();
                            }
                        }}
                    />
                </section>
            ) : (
                <section className="mio-form-field tab-flex-4 flex-12">
                    <label>Video ( Zum Speichern Enter Taste drücken )</label>
                    <input
                        type="text"
                        value={videoData.videopath}
                        onChange={(e) => setVideoData({ ...videoData, videopath: e.target.value })}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleVideo();
                            }
                        }}
                    />
                </section>
            )}
        </section>
    );
}

export default VideoForm;
