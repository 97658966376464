import React from "react";
import { useState} from "react";
import axios from "axios";
import FileUploader from "../filemanagement/FileUploader";
import apiurl from "../apiUrl";
import apiUrl from "../apiUrl";
import imageurl from "../imageurl";
import style from "../evolve-framework/evolve-forms.module.css"
import TextInput from "../forms/TextInput";
function CustomerEditForm({ cust }){

    const [formData, setFormData] = useState({cmp_logo:cust.logo, cmp_name: cust.cmp_name, cmp_str:cust.cmp_str,cmp_zip:cust.cmp_zip,cmp_city:cust.cmp_city,cmp_country:cust.cmp_country,cmp_email:cust.cmp_email
        ,cmp_tel:cust.cmp_tel,cmp_fax:cust.cmp_fax });
    const [formsend, setFormSend] = useState(false);
    const [showFileUpload, setFileUpload] = useState(false);
    const [files,setFiles] = useState({area:'logo',sendfiles:[],id:1});
    const [area,setArea] = useState("Logo");
    const [logoid, setlogoid] =useState(1);
    const refuploadLogo = React.useRef();
    const tooglefileuip = () =>{

setFileUpload(true);

        refuploadLogo.current.click();
    }
    const handlefileUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        const fileList = files.sendfiles;
        formData.append('area',area);
        formData.append('logoid',logoid);
        try {

            for (let i = 0; i < fileList.length; i++) {
                formData.append('sendfiles[]', fileList[i]);

            }

            const response = await axios.post(apiurl + 'fileuploaderlogo.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {

                setFileUpload(false);
                console.log(response.data);
                setFiles([]);

            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }

        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }

    };




    const handleSubmit = async (e) => {




        e.preventDefault();

        try {
            if(!formsend) {
                const response = await axios.post(apiUrl+'updatecustomerdata.php', formData, {
                    headers: {
                        'Content-Type': 'application/json', // Setzen Sie den richtigen Content-Type
                    },
                });

                if (response.status === 200) {
                        console.log(response.data);
                    console.log('Daten erfolgreich gesendet.');
                    setFormSend(true);

                } else {
                    // Fehler bei der Anfrage
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }


    };
    return(
        <section className={style.formwrapper}>
            {
                !formsend ? (
            <div className="inlay">
            <div className="flexrow">

        <TextInput label="Firmenname" fieldname="cmp_name" setFormData={setFormData} formData={formData} inputvalue = {formData.cmp_name} deskSize="flex-12" tabSize="tab-flex-8"/>
        <TextInput label="Straße" fieldname="cmp_str" setFormData={setFormData} formData={formData} inputvalue = {formData.cmp_str} deskSize="flex-12" tabSize="tab-flex-8"/>
        <TextInput label="PLZ" fieldname="cmp_zip" setFormData={setFormData} formData={formData} inputvalue = {formData.cmp_zip} deskSize="flex-4" tabSize="tab-flex-3"/>
        <TextInput label="Ort" fieldname="cmp_city" setFormData={setFormData} formData={formData} inputvalue = {formData.cmp_city} deskSize="flex-4" tabSize="tab-flex-3"/>
        <TextInput label="Land" fieldname="cmp_country" setFormData={setFormData} formData={formData} inputvalue = {formData.cmp_country} deskSize="flex-4" tabSize="tab-flex-3"/>
        <TextInput label="Telefon" fieldname="cmp_tel" setFormData={setFormData} formData={formData} inputvalue = {formData.cmp_tel} deskSize="flex-4" tabSize="tab-flex-3"/>
        <TextInput label="Telefax" fieldname="cmp_fax" setFormData={setFormData} formData={formData} inputvalue = {formData.cmp_fax} deskSize="flex-4" tabSize="tab-flex-3"/>
        <TextInput label="E-Mail-Adresse" fieldname="cmp_email" setFormData={setFormData} formData={formData} inputvalue = {formData.cmp_email} deskSize="flex-4" tabSize="tab-flex-3"/>

        <div className="mio-form-field tab-flex-4 flex-6 logopreview">
             <label>Ihr Logo</label>
                <img
                    alt={'Logo'}
                    src={imageurl+'/'+formData.cmp_logo}
                    title={"Logo"}
                    onClick={tooglefileuip}
                />


         </div>

                <div className="mio-form-field tab-flex-8 flex-12">
                {
                <section className="Logouploader">
                            <FileUploader files={files} setFiles={setFiles} refuploadLogo={refuploadLogo} area="edit"/>
                    {
                        showFileUpload ?(
                            <button onClick={handlefileUpload}>Logo tauschen</button>

                        ):('')
                    }

                </section>

                }


</div>


                <div className="mio-form-field tab-flex-8 flex-12">
             <button
                 className={"cta-button"}
                 onClick={handleSubmit}
                 >
                 Speichern
             </button>
         </div>

            </div>
            </div>
            ) : (
            <div className="inlay">

                <h2>Daten gespeichert</h2>
            </div>
            )}
        </section>


    )
}
export default CustomerEditForm;